@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    /* https://google-webfonts-helper.herokuapp.com */

    /* comfortaa-regular - latin */
    @font-face {
        font-family: 'Comfortaa';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src:
            local(''),
            url('/assets/fonts/comfortaa-v37-latin-regular.woff2')
                format('woff2'),
            /* Chrome 26+, Opera 23+, Firefox 39+ */
                url('/assets/fonts/comfortaa-v37-latin-regular.woff')
                format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    }

    /* comfortaa-700 - latin */
    @font-face {
        font-family: 'Comfortaa';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src:
            local(''),
            url('/assets/fonts/comfortaa-v37-latin-700.woff2') format('woff2'),
            /* Chrome 26+, Opera 23+, Firefox 39+ */
                url('/assets/fonts/comfortaa-v37-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    }

    /* montserrat-regular - latin */
    @font-face {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src:
            local(''),
            url('/assets/fonts/montserrat-v15-latin-regular.woff2')
                format('woff2'),
            /* Chrome 26+, Opera 23+, Firefox 39+ */
                url('/assets/fonts/montserrat-v15-latin-regular.woff')
                format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    }

    /* montserrat-600 - latin */
    @font-face {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-display: swap;
        src:
            local(''),
            url('/assets/fonts/montserrat-v15-latin-600.woff2') format('woff2'),
            /* Chrome 26+, Opera 23+, Firefox 39+ */
                url('/assets/fonts/montserrat-v15-latin-600.woff')
                format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    }

    /* montserrat-700 - latin */
    @font-face {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src:
            local(''),
            url('/assets/fonts/montserrat-v15-latin-700.woff2') format('woff2'),
            /* Chrome 26+, Opera 23+, Firefox 39+ */
                url('/assets/fonts/montserrat-v15-latin-700.woff')
                format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    }

    @font-face {
        font-family: 'Playfair Display';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src:
            local(''),
            url('/assets/fonts/playfair-display-v21-latin-ext_latin-regular.woff2')
                format('woff2'),
            /* Chrome 26+, Opera 23+, Firefox 39+ */
                url('/assets/fonts/playfair-display-v21-latin-ext_latin-regular.woff')
                format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    }

    /* merriweather-regular - latin */
    @font-face {
        font-family: 'Merriweather';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src:
            local(''),
            url('/assets/fonts/merriweather-v22-latin-regular.woff2')
                format('woff2'),
            /* Chrome 26+, Opera 23+, Firefox 39+ */
                url('/assets/fonts/merriweather-v22-latin-regular.woff')
                format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    }

    /* merriweather-italic - latin */
    @font-face {
        font-family: 'Merriweather';
        font-style: italic;
        font-weight: 400;
        font-display: swap;
        src:
            local(''),
            url('/assets/fonts/merriweather-v22-latin-italic.woff2')
                format('woff2'),
            /* Chrome 26+, Opera 23+, Firefox 39+ */
                url('/assets/fonts/merriweather-v22-latin-italic.woff')
                format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    }

    /* merriweather-700 - latin */
    @font-face {
        font-family: 'Merriweather';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src:
            local(''),
            url('/assets/fonts/merriweather-v22-latin-900.woff2')
                format('woff2'),
            /* Chrome 26+, Opera 23+, Firefox 39+ */
                url('/assets/fonts/merriweather-v22-latin-900.woff')
                format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    }
}

@layer utilities {
    .full-height-without-navigation {
        height: 100%;
        height: calc(100% - var(--navigation-height));
    }

    .no-tap-highlight {
        -webkit-tap-highlight-color: transparent;
    }

    .full-height-min {
        min-height: 100vh; /* Fallback for browsers that do not support Custom Properties */
        min-height: calc(var(--vh, 1vh) * 100);
    }

    .full-height-max {
        max-height: 100vh; /* Fallback for browsers that do not support Custom Properties */
        max-height: calc(var(--vh, 1vh) * 100);
    }
    @media screen(md) {
        .full-height-max {
            max-height: none;
        }
    }
}

:root {
    --navigation-height: 4rem;

    --full-height-without-navigation: calc(
        var(--vh, 1vh) * 100 - var(--navigation-height, 4rem)
    );
}

@media screen(md) {
    :root {
        --navigation-height: 5rem;
    }
}

html,
body {
    @apply text-darkgrey;
    @apply font-body;
    @apply h-full;
}

@media screen(md) {
    html,
    body {
        scrollbar-gutter: stable;
    }
}

.modal-open {
    @apply overflow-hidden;
}

a {
    @apply text-red;
}

input,
input:focus,
textarea,
textarea:focus,
button,
button:focus,
select,
select:focus,
a,
a:focus {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    outline: none;
}

button {
    @apply block;
    @apply mx-auto;
    @apply rounded-fa;
    @apply px-6;
    @apply py-4;
    @apply w-3/4;
    @apply transition duration-500;
}

/**
 * @deprecated Use cta-button component directly instead of this class
 */
.red-cta-btn {
    @apply rounded-xl border border-red bg-red text-white hover:bg-red-400 hover:text-white;
}

.red-secondary-cta-btn {
    @apply rounded-xl border border-red bg-white text-red hover:bg-red-400 hover:text-white;
}

.dropdown-item {
    @apply cursor-pointer px-4 py-1 hover:bg-grey-100;
}

button:not(.mat-button-base):disabled {
    @apply cursor-default;
    @apply bg-lightgrey;
}

button.sb-wrapper {
    @apply w-full;
}

button.light {
    @apply bg-white;
    @apply text-black;
}

button.light {
    @apply md:bg-red;
    @apply md:text-white;
}

h1,
h2,
h3,
h4 {
    @apply font-heading;
    @apply pb-2;
}

h1 {
    @apply text-4xl;
}

h2 {
    @apply text-2xl;
}

h3 {
    @apply text-xl;
}

h4 {
    @apply text-lg;
}

.header {
    @apply text-3xl;
    @apply font-semibold;
    @apply pb-2;
}

button.dark {
    @apply text-white;

    background-color: rgba(66, 66, 66, 1);
}

button.small {
    @apply rounded-fa;
    @apply text-center text-sm;
    @apply px-4 py-2;
    @apply text-white;
    @apply h-8;
    @apply leading-4;
    @apply w-full;
}

.next {
    background: #ee5769 url('assets/images/next-arrow.svg') center no-repeat;

    @apply h-12 w-12;
    @apply rounded-full;
    @apply cursor-pointer;
    @apply transition duration-1000;
}

form {
    @apply pt-5;
}

label {
    @apply block;
    @apply py-2;
}

div.form-element {
    @apply pb-5;
    @apply w-3/4;
}

input,
select,
textarea {
    background-color: rgba(255, 255, 255, 0.2);
    resize: none;

    @apply block;
    @apply rounded-fa;
    @apply px-5;
    @apply py-3;
    @apply transition duration-700;
    @apply border-transparent;
    @apply border-2;
    @apply box-border;
    @apply w-full;
}

input.mat-input-element,
select.mat-input-element,
textarea.mat-input-element {
    @apply rounded-none;
}

form.light input,
form.light select,
form.light textarea {
    @apply bg-lightergrey;
    @apply text-darkgrey;
}

form.light input::placeholder,
form.light textarea::placeholder {
    color: #606060;
}

select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: rgba(255, 255, 255, 0.2) url('assets/images/dropdown-arrow.svg')
        no-repeat 90% 50%;

    @apply pr-12;
}

form.light select {
    background: #f7f7f7 url('assets/images/dropdown-arrow-dark.svg') no-repeat
        90% 50%;
}

select option {
    @apply box-border;
    @apply bg-darkgrey;
    @apply overflow-hidden;
    @apply w-full;

    text-overflow: ellipsis;
    white-space: nowrap;
    padding-top: 2px;
    padding-bottom: 2px;
}

form.light select option {
    @apply bg-lightergrey;
}

input::placeholder,
textarea::placeholder {
    @apply text-white;
    opacity: 1;
}

input.ng-untouched,
input.ng-pristine,
textarea.ng-untouched,
textarea.ng-pristine,
select.ng-untouched,
select.ng-pristine {
    @apply border-transparent;
    @apply border-2;
}

input.ng-touched.ng-invalid,
input.ng-dirty.ng-invalid,
textarea.ng-touched.ng-invalid,
textarea.ng-dirty.ng-invalid,
select.ng-dirty.ng-invalid,
select.ng-touched.ng-invalid {
    @apply border-red;
    @apply border-2;
}

input.ng-touched.ng-valid,
input.ng-dirty.ng-valid,
textarea.ng-touched.ng-valid,
textarea.ng-dirty.ng-valid,
select.ng-touched.ng-valid,
select.ng-dirty.ng-valid {
    @apply border-green;
    @apply border-2;
}

.defaultscreen {
    @apply flex min-w-full flex-col p-6 text-white;
}

image-cropper img.source-image {
    margin: auto;
}

.flexer-col {
    @apply flex min-h-0 flex-1 flex-col;
}

.flexer-row {
    @apply flex min-h-0 flex-1 flex-row;
}

.no-shrink-flexer-col {
    @apply flex flex-grow flex-col;
}

.form-element input::placeholder {
    color: grey;
}

.form-element .iti__selected-country {
    width: 100%;
}

.form-element .iti__country-name {
    @apply !text-black;
}

.form-element .iti__flag-container {
    @apply px-2;
}

.form-element .iti__search-input {
    @apply text-black;
}

.form-element .iti {
    @apply w-full;
}

.bg-green-tick {
    background: #fff url('assets/images/getting-started/tick-green.svg')
        no-repeat 50% 50%;
}

.w-75percent {
    @apply w-3/4;
}

.w-25percent {
    @apply w-1/4;
}

fa-icon svg {
    display: inline-block;
    font-size: inherit;
    height: 1em;
    vertical-align: -0.125em;
}

fa-icon .fa-2x {
    font-size: 2em;
}
fa-icon .fa-5x {
    font-size: 5em;
}

.background-container {
    @apply min-w-full;
    @apply full-height-min;
    @apply flex flex-col;
    @apply text-white;
    @apply md:pt-10;
}

.responsive-container {
    @apply max-w-md;
    @apply w-full;
    @apply mx-auto;
}

@media screen(md) {
    .responsive-container {
        margin-left: 30%;
    }
}

.responsive-content {
    @apply h-full w-full;
    @apply absolute;
}

.responsive-4\/3 {
    @apply w-full;
    @apply relative;
    @apply aspect-h-3 aspect-w-4;
}

.responsive-square {
    @apply w-full;
    @apply relative;
    @apply aspect-h-1 aspect-w-1;
}

@media screen(md) {
    .responsive-max-height {
        max-height: 70vh;
    }
}

.no-js img.lazyload {
    display: none;
}

.no-js .no-js-hidden {
    display: none;
}

.masonry-item {
    @apply block;

    vertical-align: top;
    margin-bottom: 5px;
}

.masonry-item,
.masonry-content {
    @apply rounded-lg;
    @apply w-full;

    overflow: hidden;
}

.swiper-pagination-bullet {
    @apply bg-darkgrey;
    @apply border-darkgrey;
    @apply opacity-100;
}

.swiper-pagination-bullet-active {
    @apply bg-lightgrey;
    @apply border-lightgrey;
}

youtube-player > youtube-player-placeholder,
youtube-player > div,
youtube-player iframe {
    width: 100% !important;
    height: 100% !important;
}
